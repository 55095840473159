/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');
html, body {
  background-image: url('http://getwallpapers.com/wallpaper/full/1/d/1/834398-top-water-drops-wallpaper-2560x1600.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  font-family: 'Numans', sans-serif;
}

.login-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 330px;
  padding: 15px;
  height: 400px;
}

.form-group span {
  width: 50px;
  background-color: rgb(232, 240, 254) !important;
  color: black !important;
  border: 0 !important;
}

div.input-group input {
  background-color: rgb(232, 240, 254) !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.login_btn {
  color: black;
  background-color: #FFC312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}